import API from '@/services/api'
import store from '@/store'
import {
  CHECK_PRODUCT_STOCK,
  CHECK_PRODUCT_STOCK_ARRAY,
  CHECK_PRODUCT_PRICE,
  SEARCH_PRODUCT,
  GET_PRODUCT,
  GET_PARTS,
  GET_PARTS_PRODUCT,
  GET_PRODUCT_RELATED,
  GET_PRICE_ADD,
  UPDATE_PRICE_ADD
} from '@/services/enpoints'

const checkStock = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: CHECK_PRODUCT_STOCK,
    data,
  })
}

const checkPrice = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: CHECK_PRODUCT_PRICE,
    data,
  })
}

const checkStockArray = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: CHECK_PRODUCT_STOCK_ARRAY,
    data,
  })
}

const search = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: SEARCH_PRODUCT,
    data,
  })
}

const get = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_PRODUCT,
    data,
  })
}

const getParts = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_PARTS,
    data,
  })
}

const getPartsProducts = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_PARTS_PRODUCT,
    data,
  })
}

const getRelated = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_PRODUCT_RELATED,
    data,
  })
}

const getPriceAdd = () => {
  const authToken = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_PRICE_ADD,
    data: { 'token': authToken },
  })
}

const updatePriceAdd = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: UPDATE_PRICE_ADD,
    data,
  })
}

export const productService = {
  checkStock,
  checkPrice,
  checkStockArray,
  search,
  get,
  getParts,
  getPartsProducts,
  getRelated,
  getPriceAdd,
  updatePriceAdd,
}
