import { productService } from '@/services'
import { mapActions } from 'vuex'
import logger from '@/logger/index'

const TYPES = {
  SET_PARTS_PRICES: 'SET_PARTS_PRICES',
  SELECT_PARTS_PRODUCT: 'SELECT_PARTS_PRODUCT',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_PRODUCT_PRICES: 'SET_PRODUCT_PRICES',
  SET_PRODUCT_STOCK: 'SET_PRODUCT_STOCK',
  SET_PRODUCT_STOCK_ARRAY: 'SET_PRODUCT_STOCK_ARRAY',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SET_PARTS: 'SET_PARTS',
  SET_RELATED_PRODUCT: 'SET_RELATED_PRODUCT',
  SET_PRICEADD: 'SET_PRICEADD',
  RESTORE_RELATED_PRODUCT: 'RESTORE_RELATED_PRODUCT',
  SET_PARTS_PRODUCTS: 'SET_PARTS_PRODUCTS',
  REMOVE_SELECTED_PARTS: 'REMOVE_SELECTED_PARTS',
  ADD_SELECTED_PARTS: 'ADD_SELECTED_PARTS',
  SET_SELECTED_PARTS_PRODUCTS: 'SET_SELECTED_PARTS_PRODUCTS',
}

const state = () => ({
  items: [],
  parts: [], // conjunto de peças
  partProducts: [], // lista de produtos do conjunto
  selectedParts: {}, // conjunto de peças selecionado da tabela
  selectedProduct: null, // produto selecionado referente a produto-detalhes
  priceAdd: null, // preço do acréscimo do produto
  relatedProducts: [], // produtos relacionados
})

const getters = {
  getAllProducts: (state) => {
    return state.items
  },

  getAllRelatedProducts: (state) => {
    return state.relatedProducts
  },

  getAllParts: (state) => {
    return state.parts
  },

  getPartProducts: (state) => {
    return state.partProducts
  },

  getPriceAdd: (state) => {
    return state.priceAdd
  },

  getSelectedParts: (state) => {
    return state.selectedParts
  },

  getSelectedProduct: (state) => {
    return state.selectedProduct
  },
}

const actions = {
  searchProducts ({ commit }, params) {
    commit(TYPES.SET_PRODUCTS, [])
    commit(TYPES.SELECT_PRODUCT, null)
    return new Promise((resolve, reject) => {
      productService.search(params).then(({ data }) => {
        if (Array.isArray(data) && data.length) {
          commit(TYPES.SET_PRODUCTS, data)
          resolve(data)
        } else {
          resolve({ notFound: true })
        }
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  async getProduct ({ commit, dispatch }, params) {
    commit(TYPES.SET_PRODUCTS, [])
    commit(TYPES.SELECT_PRODUCT, null)
    return new Promise((resolve, reject) => {
      productService.get(params).then(({ data }) => {
        if (data && data.id !== 0) {
          commit(TYPES.SET_PRODUCT, data)
          dispatch('checkProductStock', { id: data.id })
          resolve(data)
        } else {
          resolve({ notFound: true })
        }
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  getProductDiscreet ({ commit }, params) {
    commit(TYPES.SELECT_PARTS_PRODUCT, null)
    return new Promise((resolve, reject) => {
      productService.get(params).then(({ data }) => {
        commit(TYPES.SELECT_PARTS_PRODUCT, data)
        resolve(data)
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  async getParts ({ commit }, params) {
    commit(TYPES.SET_PARTS, [])
    await productService.getParts(params).then(({ data }) => {
      commit(TYPES.SET_PARTS, data)
    })
  },

  async getPartsProducts ({ commit, dispatch }, params) {
    try {
      commit(TYPES.SET_PARTS_PRODUCTS, [])
      const { data } = await productService.getPartsProducts(params)
      commit(TYPES.SET_PARTS_PRODUCTS, data)
      commit(TYPES.SET_SELECTED_PARTS_PRODUCTS, data)

      const codProducts = data.map(product => parseInt(product.product_id))
      await dispatch('checkPartsProductStockArray', { array: codProducts })

      return data
    } catch (error) {
      if (error.response) {
        throw error.response.data
      } else {
        throw error
      }
    }
  },

  async getRelatedProducts ({ commit }, params) {
    commit(TYPES.SET_PARTS, [])
    await productService.getRelated(params).then(({ data }) => {
      commit(TYPES.SET_PARTS, data)
      commit(TYPES.SET_RELATED_PRODUCT, data)
      return data
    })
  },

  restoreRelatedProducts ({ commit }) {
    commit(TYPES.RESTORE_RELATED_PRODUCT)
  },

  checkPriceAdd ({ commit }) {
    return new Promise((resolve, reject) => {
      productService.getPriceAdd().then(({ data }) => {
        commit(TYPES.SET_PRICEADD, data)
        resolve(data)
      }).catch(({ response }) => {
        reject(response)
      })
    })
  },

  checkProductPrice ({ commit }, params) {
    return new Promise((resolve, reject) => {
      productService.checkPrice(params).then(({ data }) => {
        commit(TYPES.SET_PRODUCT_PRICES, data)
        resolve(data)
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  checkPartsProductPrice ({ commit }, params) {
    return new Promise((resolve, reject) => {
      productService.checkPrice(params).then(({ data }) => {
        if (data) {
          let product = {
            id: params.id,
            price: data,
          }
          commit(TYPES.SET_PARTS_PRICES, product)
        }
        resolve(data)
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  checkProductStock ({ commit }, params) {
    return new Promise((resolve, reject) => {
      productService.checkStock(params).then(({ data }) => {
        commit(TYPES.SET_PRODUCT_STOCK, data)
        resolve(data)
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  async checkPartsProductStockArray ({ getters, commit }, params) {
    logger.debug('Entrou na verificação no array de estoques de vários produtos')

    try {
      const { data } = await productService.checkStockArray(params)

      // console.time()
      logger.debug('getters.getSelectedParts:', getters.getSelectedParts)
      if (getters.getSelectedParts && getters.getSelectedParts.products !== undefined) {
        // Condição atendida, resolva a Promise
        commit(TYPES.SET_PRODUCT_STOCK_ARRAY, data)
        logger.debug('Condição atendida')
        // console.timeEnd()
        return
      }
    } catch (response) {
      throw new Error(response !== undefined ? (response.data) : (response))
    }
  },

  removeSelectedParts ({ commit }) {
    commit(TYPES.REMOVE_SELECTED_PARTS)
  },

  addSelectedParts ({ commit }, payload) {
    commit(TYPES.ADD_SELECTED_PARTS, payload)
  },

  selectProduct ({ commit, dispatch }, params) {
    commit(TYPES.SELECT_PRODUCT, params)
    dispatch('checkProductStock', { id: params.search })
    commit(TYPES.SET_PRODUCTS, [])
    commit(TYPES.SET_PRODUCT_PRICES, [])
  },

  updatePriceAdd ({ commit }, params) {
    return new Promise((resolve, reject) => {
      productService.updatePriceAdd(params).then(({ data }) => {
        resolve(data)
      }).catch(({ response }) => {
        reject(response)
      })
    })
  },
}

const mutations = {
  [TYPES.SET_PRODUCTS] (state, payload) {
    state.items = [...payload]
  },

  [TYPES.SELECT_PARTS_PRODUCT] (state, payload) {
    if (payload !== 0 || null) {
      const index = state.selectedParts.products.findIndex(product => product.product_id === payload?.id)
      state.selectedParts.products[index] = { ...payload, ...state.selectedParts.products[index] }
    }
  },

  [TYPES.SELECT_PRODUCT] (state, payload) {
    state.selectedProduct = payload
  },

  [TYPES.SET_PRODUCT] (state, payload) {
    state.selectedProduct = null
    if (payload.code > 0) {
      state.selectedProduct = payload
    }
  },

  [TYPES.SET_PRODUCT_PRICES] (state, payload) {
    state.selectedProduct.price = payload
  },

  [TYPES.SET_PARTS_PRICES] (state, payload) {
    state.selectedParts.products.find(product => product.product_id === payload?.id).price = payload.price
  },

  [TYPES.SET_PRODUCT_STOCK] (state, payload) {
    state.selectedProduct.stock = payload
  },

  [TYPES.SET_PRODUCT_STOCK_ARRAY] (state, payload) {
    for (const index in payload) {
      if (payload.hasOwnProperty(index)) {
        state.selectedParts.products.find(product => product.product_id === parseInt(index)).stock = payload[index]
      }
    }
  },

  [TYPES.SET_PARTS] (state, payload) {
    state.parts = payload
  },

  [TYPES.SET_PRICEADD] (state, payload) {
    state.priceAdd = payload
  },

  [TYPES.SET_RELATED_PRODUCT] (state, payload) {
    state.relatedProducts = [...payload]
  },

  [TYPES.RESTORE_RELATED_PRODUCT] (state) {
    state.parts = state.relatedProducts
  },

  [TYPES.SET_PARTS_PRODUCTS] (state, payload) {
    state.partProducts = payload
  },

  [TYPES.SET_SELECTED_PARTS_PRODUCTS] (state, payload) {
    state.selectedParts.products = payload.map(product => ({ product_id: product.product_id }))
  },

  [TYPES.ADD_SELECTED_PARTS] (state, payload) {
    state.selectedParts.id = parseInt(payload.id)
    state.selectedParts.description = payload.description
  },

  [TYPES.REMOVE_SELECTED_PARTS] (state) {
    state.selectedParts = {}
    state.partProducts = []
  },
}

export default {
  methods: {
    ...mapActions(['setHeaderBack', 'setFeedback']),
  },
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
