import { orderService } from '@/services'
import { mapGetters, mapActions } from 'vuex'
import logger from '@/logger/index'
import Vue from 'vue'

const TYPES = {
  SELECT_ORDER: 'SELECT_ORDER',
  REMOVE_SELECT_ORDER: 'REMOVE_SELECT_ORDER',
  SET_ORDERS: 'SET_ORDERS',
  SET_MORE_ORDERS: 'SET_MORE_ORDERS',
  SET_CHECKOUT_ORDER: 'SET_CHECKOUT_ORDER',
  SET_NEW_ORDER: 'SET_NEW_ORDER',
  REMOVE_PRODUCT_ORDER: 'REMOVE_PRODUCT_ORDER',
  REMOVE_ORDER: 'REMOVE_ORDER',
  UPDATE_ORDER_NAME: 'UPDATE_ORDER_NAME',
  UPDATE_ORDER_OBS: 'UPDATE_ORDER_OBS',
  ADD_PRODUCT_ORDER: 'ADD_PRODUCT_ORDER',
  UPDATE_QTD_PRODUCT: 'UPDATE_QTD_PRODUCT',
  SET_STATUS_TABLE: 'SET_STATUS_TABLE',
  REMOVE_STATUS_TABLE: 'REMOVE_STATUS_TABLE',
}

const state = () => ({
  items: [],
  selectedOrder: null,
  statusTable: false,
})

const getters = {
  orders: (state) => {
    return state.items
  },

  total: (state) => {
    return state.items.length
  },

  totalInProgress: (state) => {
    return state.items.filter(order => order.status === 'Em andamento').length
  },

  totalFinished: (state) => {
    return state.items.filter(order => order.status === 'Finalizado').length
  },

  totalRecords: (state) => {
    return state.items[0]?.total_records
  },

  getSelectedOrder: (state) => {
    return state.selectedOrder
  },

  getStatusTable: (state) => {
    return state.statusTable
  },
}

const actions = {
  selectOrder ({ commit, dispatch }, params) {
    dispatch('products/removeSelectedParts', {}, { root: true })
    commit(TYPES.SET_STATUS_TABLE)
    commit(TYPES.SELECT_ORDER, params)
  },

  removeSelectOrder ({ commit }) {
    commit(TYPES.REMOVE_SELECT_ORDER)
    this.dispatch('setFeedback', ({ title: 'Este pedido já está finalizado', message: '', type: 'error' }))
  },

  getAllOrders ({ commit }, params) {
    let order = {
      ...params,
    }
    return new Promise((resolve, reject) => {
      orderService.getAll(order).then(({ data }) => {
        commit(TYPES.SET_ORDERS, data)
        resolve(data)
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  getMoreOrders ({ commit }, params) {
    let order = {
      ...params,
    }
    return new Promise((resolve, reject) => {
      orderService.getAll(order).then(({ data }) => {
        commit(TYPES.SET_MORE_ORDERS, data)
        resolve(data)
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  getOrderCart ({ commit, dispatch }, params) {
    let order = {
      ...params,
    }

    return new Promise((resolve, reject) => {
      orderService
        .getOrderCart(order)
        .then(({ data }) => {
          commit(TYPES.SET_ORDERS, data)
          let selectedOrderId = order && order.id ? order.id : data[0].id
          dispatch('selectOrder', { id: selectedOrderId }).then(() => {
            resolve()
          })
            .catch(({ response }) => reject(response))
        })
        .catch(({ response }) => {
          if (response !== undefined) {
            reject(response.data)
          } else {
            reject(response)
          }
        })
    })
  },

  checkoutOrder ({ commit }, params) {
    return new Promise((resolve, reject) => {
      orderService.checkout(params).then(({ data }) => {
        commit(TYPES.SET_CHECKOUT_ORDER, params)
        resolve(params)
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  addNewOrder ({ commit }, params) {
    return new Promise((resolve, reject) => {
      orderService.add(params).then(({ data }) => {
        commit(TYPES.SET_NEW_ORDER, data)
        resolve(data)
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  deleteOrder ({ commit }, params) {
    return new Promise((resolve, reject) => {
      orderService.remove(params).then(() => {
        commit(TYPES.REMOVE_ORDER, params)
        resolve()
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  updateOrderName ({ commit }, params) {
    return new Promise((resolve, reject) => {
      orderService.updateOrderName(params).then(({ data }) => {
        commit(TYPES.UPDATE_ORDER_NAME, params)
        resolve()
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  updateOrderObs ({ commit }, params) {
    return new Promise((resolve, reject) => {
      orderService.updateOrderObs(params).then(({ data }) => {
        commit(TYPES.UPDATE_ORDER_OBS, params)
        resolve()
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  removeProduct ({ commit }, params) {
    return new Promise((resolve, reject) => {
      orderService.removeProduct(params).then(({ data }) => {
        commit(TYPES.REMOVE_PRODUCT_ORDER, params)
        resolve()
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  updateProductQuantity ({ commit, state }, params) {
    let product = {
      product: params.id,
      quantity: params.quantity,
      order: state.selectedOrder.id,
    }
    return new Promise((resolve, reject) => {
      orderService.updateProductQuantity(product).then(({ data }) => {
        commit(TYPES.UPDATE_QTD_PRODUCT, params)
        resolve()
      }).catch(({ response }) => {
        reject(response.data)
      })
    })
  },

  addProduct ({ commit, state }, params) {
    let product = {
      ...params.product,
      price: params.price,
      list_type: params.list_type,
      quantity: params.quantity,
      order: state.selectedOrder.id,
    }

    return new Promise((resolve, reject) => {
      orderService.addProduct(product).then(({ data }) => {
        if (data.message === '') {
          commit(TYPES.ADD_PRODUCT_ORDER, product)
        }
        resolve(logger.debug('addProduct: ', product))
      }).catch(({ response }) => {
        response !== undefined ? reject(response.data) : reject(response)
      })
    })
  },

  removeStatusTable ({ commit }) {
    commit(TYPES.REMOVE_STATUS_TABLE)
  },
}

const mutations = {
  [TYPES.SET_ORDERS] (state, payload) {
    state.items = [...payload]
  },

  [TYPES.SET_MORE_ORDERS] (state, payload) {
    state.items.push(...payload)
  },

  [TYPES.SET_CHECKOUT_ORDER] (state, payload) {
    state.items.find(order => order.id === payload.id).status = 'Finalizado'
    state.selectedOrder = state.items.find(order => order.status === 'Em andamento')
  },

  [TYPES.SET_NEW_ORDER] (state, payload) {
    payload.status = 'Em andamento'
    state.items.push(payload)
    state.selectedOrder = state.items.find(order => order.id === payload.id)
  },

  [TYPES.REMOVE_PRODUCT_ORDER] (state, payload) {
    const order = state.items.find(order => order.id === payload.id)
    const productIndex = order.products.findIndex(product => product.id === payload.product_id)
    if (productIndex !== -1) {
      Vue.set(order.products, productIndex, undefined)
      order.products = order.products.filter(product => product !== undefined)
    }
  },

  [TYPES.REMOVE_ORDER] (state, payload) {
    state.selectedOrder = null
  },

  [TYPES.UPDATE_ORDER_NAME] (state, payload) {
    state.items.find(order => order.id === payload.id).name = payload.name
  },

  [TYPES.UPDATE_ORDER_OBS] (state, payload) {
    state.items.find(order => order.id === payload.id).obs = payload.obs
  },

  [TYPES.SELECT_ORDER] (state, payload) {
    state.selectedOrder = state.items.find(order => order.id === payload.id)
  },

  [TYPES.ADD_PRODUCT_ORDER] (state, payload) {
    state.selectedOrder.products.push(payload)
  },

  [TYPES.UPDATE_QTD_PRODUCT] (state, payload) {
    let product = state.selectedOrder.products.find(products => products.id === payload.id)
    if (product) {
      product.quantity = payload.quantity
      product.total_price = product.price * product.quantity
    }
  },
  [TYPES.SET_STATUS_TABLE] (state) {
    state.statusTable = true
  },
  [TYPES.REMOVE_STATUS_TABLE] (state) {
    state.statusTable = false
  },

  [TYPES.REMOVE_SELECT_ORDER] (state) {
    state.selectedOrder = null
  },
}

export default {
  computed: {
    ...mapGetters('auth', ['getUserName']),
  },
  methods: {
    ...mapActions(['setFeedback']),
  },
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
