import API from '@/services/api'
import store from '@/store'
import {
  LOGIN_URL,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD
} from '@/services/enpoints'

const login = (data) => {
  return API.call({
    method: 'POST',
    url: LOGIN_URL,
    data,
  })
}

const recoverPassword = (data) => {
  return API.call({
    method: 'POST',
    url: RECOVER_PASSWORD,
    data,
  })
}

const changePassword = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: CHANGE_PASSWORD,
    data,
  })
}

export const authService = {
  login,
  recoverPassword,
  changePassword,
}
