import { vehicleService, productService } from '@/services'
// import logger from '@/logger/index'
const TYPES = {
  SET_REGISTRATION_PLATE: 'SET_REGISTRATION_PLATE',
  SET_VEHICLE_MODEL: 'SET_VEHICLE_MODEL',
  SET_SEARCH_SUGGESTIONS: 'SET_SEARCH_SUGGESTIONS',
  SELECT_SEARCH_SUGGESTION: 'SELECT_SEARCH_SUGGESTION',
  SET_ITEMS_TABLE: 'SET_ITEMS_TABLE',
}

const state = () => ({
  items: [],
  model: null,
  listSuggestions: [],
  registrationPlate: null,
  selectedSuggestion: null,
})

const getters = {
  getModel: (state) => {
    return state.model
  },

  getListSuggestions: (state) => {
    return state.listSuggestions
  },

  getRegistrationPlate: (state) => {
    return state.registrationPlate
  },

  getSelectedSuggestion: (state) => {
    return state.selectedSuggestion
  },

  getItemsTable: (state) => {
    return state.items
  },
}

const actions = {
  getVehiclePlate ({ commit, dispatch }, params) {
    commit(TYPES.SET_VEHICLE_MODEL, null)
    return new Promise((resolve, reject) => {
      vehicleService.apiPlate({
        plate: params,
      })
        .then(({ data }) => {
          commit(TYPES.SET_SEARCH_SUGGESTIONS, [])
          commit(TYPES.SELECT_SEARCH_SUGGESTION, null)
          commit(TYPES.SET_ITEMS_TABLE, [])
          commit(TYPES.SET_VEHICLE_MODEL, data.resposta[0])
          dispatch('handleSearchSuggestions', data.resposta[0])
          resolve(data)
        }).catch(({ response }) => {
          reject(response)
        })
    })
  },

  handleSearchSuggestions ({ commit }, params) {
    commit(TYPES.SET_SEARCH_SUGGESTIONS, [])
    return new Promise((resolve, reject) => {
      vehicleService.searchSuggestions({
        model: params,
      })
        .then(({ data }) => {
          commit(TYPES.SET_SEARCH_SUGGESTIONS, data)
          resolve(data)
        }).catch(({ response }) => {
          reject(response)
        })
    })
  },

  addRegistrationPlate ({ commit }, params) {
    commit(TYPES.SET_REGISTRATION_PLATE, params)
  },

  selectSearchSuggestion ({ commit }, params) {
    commit(TYPES.SELECT_SEARCH_SUGGESTION, params)
  },

  searchProducts ({ commit }, params) {
    commit(TYPES.SET_ITEMS_TABLE, [])
    return new Promise((resolve, reject) => {
      productService.search(params)
        .then(({ data }) => {
          commit(TYPES.SET_ITEMS_TABLE, data)
          resolve(data)
        }).catch(({ response }) => {
          if (response !== undefined) {
            reject(response)
          }
        })
    })
  },

}

const mutations = {
  [TYPES.SET_VEHICLE_MODEL] (state, payload) {
    state.model = payload
  },

  [TYPES.SET_SEARCH_SUGGESTIONS] (state, payload) {
    state.listSuggestions = [...payload]
  },

  [TYPES.SELECT_SEARCH_SUGGESTION] (state, payload) {
    state.selectedSuggestion = payload
  },

  [TYPES.SET_REGISTRATION_PLATE] (state, payload) {
    state.registrationPlate = payload
  },

  [TYPES.SET_ITEMS_TABLE] (state, payload) {
    state.items = [...payload]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
