import store from '@/store'
import API from '@/services/api'
import {
  GET_ORDERS,
  GET_ORDER_CART,
  ADD_ORDER,
  ADD_PRODUCT_ORDER,
  REMOVE_PRODUCT_ORDER,
  UPDATE_PRODUCT_ORDER,
  CAN_SEND_ORDER,
  UPDATE_ORDER_NAME,
  UPDATE_ORDER_OBS,
  DELETE_ORDER,
  CHECKOUT_ORDER
} from '@/services/enpoints'

const getAll = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_ORDERS,
    data,
  })
}

const getOrderCart = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: GET_ORDER_CART,
    data,
  })
}

const add = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: ADD_ORDER,
    data,
  })
}

const addProduct = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: ADD_PRODUCT_ORDER,
    data,
  })
}

const removeProduct = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: REMOVE_PRODUCT_ORDER,
    data,
  })
}

const updateProductQuantity = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: UPDATE_PRODUCT_ORDER,
    data,
  })
}

const canSend = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: CAN_SEND_ORDER,
    data,
  })
}

const updateOrderName = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: UPDATE_ORDER_NAME,
    data,
  })
}

const updateOrderObs = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: UPDATE_ORDER_OBS,
    data,
  })
}

const remove = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: DELETE_ORDER,
    data,
  })
}

const checkout = (data) => {
  data.token = store.state.auth.token
  return API.call({
    method: 'POST',
    url: CHECKOUT_ORDER,
    data,
  })
}

export const orderService = {
  getAll,
  getOrderCart,
  add,
  addProduct,
  removeProduct,
  updateProductQuantity,
  canSend,
  updateOrderName,
  updateOrderObs,
  remove,
  checkout,
}
