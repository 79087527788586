import pino from 'pino'

const isProduction = process.env.NODE_ENV === 'production'

const logger = pino({
  level: isProduction ? 'info' : 'debug',
  prettyPrint: true,
})

logger.debug('Mensagem de log de depuração')
export default logger
