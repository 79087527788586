import axios from 'axios'
import { API_URL } from '@/config'
import store from '@/store'
import router from '@/router'

const API = axios.create({
  baseURL: API_URL,
})

API.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    store.dispatch('auth/logout')
    router.push({ name: 'login' })
  }
  return Promise.reject(error)
})

const setToken = (token) => {
  API.defaults.headers.common.Authorization = token
}

const unsetToken = () => {
  delete API.defaults.headers.common.Authorization
}

const deleteLocalStorage = () => {
  localStorage.removeItem('vuex')
}

// Criado para armazenar as requisições pendentes
let pendingRequests = {}

const call = ({ method, header, data, url, success, error }) => {
  const options = {
    header,
    method,
    url,
    data,
    cancelToken: new axios.CancelToken((cancel) => {
      // key é gerado a partir do método e url, para verificar se já existe uma requisição pendente igual
      const key = `${method}-${url}`
      if (pendingRequests[key]) {
        // Cancela a requisição anterior
        pendingRequests[key].cancel()
      }

      // Adiciona a nova requisição à lista de requisições pendentes
      pendingRequests[key] = { cancel }
    }),
  }

  return API(options).then(success).catch(error)
}

var crypto = require('crypto-browserify')

const algorithm = 'aes-256-ctr'
const password = 'fcdca36e6c259f521fddef3d7dcc67b2'

function encrypt (text) {
  const IV = crypto.randomBytes(16)
  const cipher = crypto.createCipheriv(algorithm, password, IV)
  let crypted = IV.toString('hex') + cipher.update(text, 'utf8', 'hex')
  crypted += cipher.final('hex')
  return crypted
}

function decrypt (text) {
  if (text == null || text.length < 32) {
    return null
  }
  const IV = Buffer.from(text.substr(0, 32), 'hex')
  const encrypted = text.substring(32)
  const decipher = crypto.createDecipheriv(algorithm, password, IV)
  let decrypted = decipher.update(encrypted, 'hex', 'utf8')
  decrypted += decipher.final('utf8')
  return decrypted
}

export default {
  call,
  setToken,
  unsetToken,
  deleteLocalStorage,
  encrypt,
  decrypt,
}
