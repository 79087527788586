import { permissionsService } from '@/services/permissions.service'

const TYPES = {
  ENABLE_PARTS: 'ENABLE_PARTS',
  FAVORITE_COMPANY: 'FAVORITE_COMPANY',
  BLOCK_SALE: 'BLOCK_SALE',
}

const state = () => ({
  enableParts: 0,
  favoriteCompany: null,
  blockSaleWithoutBalance: null,
})

const getters = {
  getEnableParts: (state) => {
    return state.enableParts
  },

  getFavoriteCompany: (state) => {
    return state.favoriteCompany
  },

  getBlockSale: (state) => {
    return state.blockSaleWithoutBalance
  },
}

const actions = {
  getPermissions ({ commit }, params) {
    return new Promise((resolve, reject) => {
      permissionsService.checkPermissions(params)
        .then(({ data: { permissions } }) => {
          commit(TYPES.ENABLE_PARTS, permissions)
          commit(TYPES.FAVORITE_COMPANY, permissions)
          commit(TYPES.BLOCK_SALE, permissions)
          resolve(permissions)
        }).catch(({ response }) => {
          reject(response)
        })
    })
  },
}

const mutations = {
  [TYPES.ENABLE_PARTS] (state, payload) {
    state.enableParts = payload.CONJUNTO_HABILITADO
  },

  [TYPES.FAVORITE_COMPANY] (state, payload) {
    state.favoriteCompany = parseInt(payload.EMPRESA_PADRAO_TDRSCHERER)
  },

  [TYPES.BLOCK_SALE] (state, payload) {
    state.blockSaleWithoutBalance = parseInt(payload.BLOQUEAR_VENDA_SEM_SALDO)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
